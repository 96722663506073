var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DashboardLayout from "../Layout/DashboardLayout";
import { useEffect, useState } from "react";
import { useAPI } from "@/hooks/useAPI";
import { useRecoilValue } from "recoil";
import { merchantState } from "../Home/state";
import moment from "moment";
var NotificationIcon = {
    PAYMENT_CREATED: (_jsx("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M6 8H0V6H6V0H8V6H14V8H8V14H6V8Z", fill: "#22E52D" }) })),
    PAYMENT_RECEIVED: (_jsx("svg", { width: "20", height: "14", viewBox: "0 0 20 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M3.825 8L8.4 12.6L7 14L8.34742e-08 7L7 8.34742e-08L8.425 1.4L3.825 6L11 6L11 8L3.825 8ZM16 8L13 8L13 6L16 6L16 8ZM20 8L18 8L18 6L20 6L20 8Z", fill: "#22E62D" }) })),
    PAYMENT_FAILED: (_jsx("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z", fill: "#FF6A65" }) })),
    REFUND_REQUESTED: (_jsx("svg", { width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M6 8H0V6H6V0H8V6H14V8H8V14H6V8Z", fill: "#FF6A65" }) })),
    REFUND_PROCESSED: (_jsx("svg", { width: "20", height: "14", viewBox: "0 0 20 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M16.175 6L11.6 1.4L13 -3.0598e-07L20 7L13 14L11.575 12.6L16.175 8L9 8L9 6L16.175 6ZM4 6L7 6L7 8L4 8L4 6ZM-2.62268e-07 6L2 6L2 8L-3.49691e-07 8L-2.62268e-07 6Z", fill: "#FF6A65" }) })),
    SETTLEMENT: (_jsx("svg", { width: "16", height: "20", viewBox: "0 0 16 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M4 10V8H12V10H4ZM4 6V4H12V6H4ZM2 12H9.5C9.98333 12 10.4333 12.1042 10.85 12.3125C11.2667 12.5208 11.6167 12.8167 11.9 13.2L14 15.95V2H2V12ZM2 18H13.05L10.325 14.425C10.225 14.2917 10.1042 14.1875 9.9625 14.1125C9.82083 14.0375 9.66667 14 9.5 14H2V18ZM14 20H2C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H14C14.55 0 15.0208 0.195833 15.4125 0.5875C15.8042 0.979167 16 1.45 16 2V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20Z", fill: "#454E5F" }) })),
};
export default function NotificationsPage() {
    var _this = this;
    var hitAPI = useAPI();
    var merchant = useRecoilValue(merchantState);
    var _a = useState([]), notifications = _a[0], setNotifications = _a[1];
    var getNotifications = function (merchant) { return __awaiter(_this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, hitAPI("/merchant/".concat(merchant.id, "/notification?per_page=20"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    setNotifications(data.data.data);
                    return [2 /*return*/];
            }
        });
    }); };
    var markAsRead = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!merchant)
                        return [2 /*return*/];
                    return [4 /*yield*/, hitAPI("/merchant/".concat(merchant.id, "/notification/mark-all-as-read"), "POST")];
                case 1:
                    _a.sent();
                    getNotifications(merchant);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        merchant && getNotifications(merchant);
    }, []);
    return (_jsx(DashboardLayout, { title: "Notifications", children: _jsxs("div", { className: "rounded-2xl bg-white py-6", style: {
                boxShadow: "2px 4px 6px 0px rgba(72, 83, 73, 0.04)",
            }, children: [_jsxs("div", { className: "flex items-center justify-between px-5", children: [_jsx("h1", { className: "text-xl text-[#123750]", children: "Notifications" }), _jsxs("button", { onClick: markAsRead, className: "flex items-center gap-3 rounded-lg border border-[#1BC5BD] px-4 py-3 text-xs font-semibold text-[#1BC5BD]", children: ["Mark all as read", _jsx("svg", { width: "18", height: "10", viewBox: "0 0 18 10", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M5.0251 9.5002L0.787598 5.2627L1.85635 4.2127L5.04385 7.4002L6.09385 8.4502L5.0251 9.5002ZM9.2626 9.5002L5.0251 5.2627L6.0751 4.19395L9.2626 7.38145L16.1626 0.481445L17.2126 1.5502L9.2626 9.5002ZM9.2626 5.2627L8.19385 4.2127L11.9063 0.500195L12.9751 1.5502L9.2626 5.2627Z", fill: "#1BC5BD" }) })] })] }), _jsx("div", { className: "mx-5 my-4", children: notifications.length > 0
                        ? notifications.map(function (notification) { return (_jsxs("div", { className: "flex justify-between border border-l-[6px] border-[#E7EEFE] border-r-0 ".concat(notification.read_at === null
                                ? "border-l-[#0064A7] bg-[#F7F5FF]"
                                : "border-l-white bg-white", " px-4 py-3"), children: [_jsxs("div", { className: "flex items-center gap-4", children: [NotificationIcon[notification.data.type], _jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("p", { className: "text-xs font-semibold text-[#454E5F]", children: notification.title }), _jsx("p", { className: "text-xs text-[#454E5F]", children: notification.body }), _jsx("p", { className: "text-[0.625rem] text-[#BDBDBD]", children: notification.data.footer })] })] }), _jsx("p", { className: "text-[#737373] text-[0.625rem] mr-2", children: moment(notification.created_at).fromNow() })] })); })
                        : _jsx("p", { className: "text-[#737373] text-center text-sm", children: "No notifications for now \uD83D\uDE0A" }) })] }) }));
}
