import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { RecoilRoot } from "recoil";

import { Toaster } from "react-hot-toast";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RecoilRoot>
            <Toaster />
            <BrowserRouter>
                <AppRoutes />
            </BrowserRouter>
        </RecoilRoot>
    </React.StrictMode>
);
