var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export default function Input(_a) {
    var label = _a.label, name = _a.name, placeholder = _a.placeholder, _b = _a.required, required = _b === void 0 ? false : _b, _c = _a.type, type = _c === void 0 ? "text" : _c, _d = _a.reversed, reversed = _d === void 0 ? false : _d, props = __rest(_a, ["label", "name", "placeholder", "required", "type", "reversed"]);
    return (_jsxs("div", { className: "flex gap-2 w-full ".concat(reversed ? "flex-row-reverse" : "flex-col"), children: [_jsxs("label", { className: "text-xs text-[#858585] font-medium", children: [label, required && _jsx("span", { className: "text-[#EB5757]", children: "*" })] }), _jsx("input", __assign({ type: type, className: "bg-[#FAFAFA] border border-[#B5B5C3] py-3 px-4 rounded-[5rem] text-xs", placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : label, name: name }, props))] }));
}
