import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Table from "../Table/Table";
import Tbody from "../Table/Tbody";
import Td from "../Table/Td";
import Th from "../Table/Th";
import Thead from "../Table/Thead";
import Tr from "../Table/Tr";
import moment from "moment";
export default function ChangeRequestTable(_a) {
    var data = _a.data;
    return (_jsxs(Table, { children: [_jsx(Thead, { children: _jsxs(Tr, { children: [_jsx(Th, { children: "Type" }), _jsx(Th, { children: "Old Value" }), _jsx(Th, { children: "New Value" }), _jsx(Th, { children: "Status" }), _jsx(Th, { children: "Reason (if rejected)" }), _jsx(Th, { children: "Requested at" }), _jsx(Th, { children: "Last updated at" })] }) }), _jsx(Tbody, { children: data.length > 0 ? (data.map(function (change, index) {
                    var _a;
                    console.log(change.old_value);
                    return (_jsxs(Tr, { children: [_jsx(Td, { children: change.type.replaceAll("_", " ") }), _jsx(Td, { children: change.old_value
                                    ? Object.keys(change.old_value).map(function (key) {
                                        return (_jsxs(_Fragment, { children: [key, ":", " ", change.old_value[key], _jsx("br", {})] }));
                                    })
                                    : "CREATE" }), _jsx(Td, { children: change.new_value
                                    ? Object.keys(change.new_value).map(function (key) {
                                        return (_jsxs(_Fragment, { children: [key, ":", " ", change.new_value[key], _jsx("br", {})] }));
                                    })
                                    : "DELETE" }), _jsx(Td, { children: change.status }), _jsx(Td, { children: (_a = change.reason) !== null && _a !== void 0 ? _a : "-" }), _jsx(Td, { children: moment(change.created_at).format("DD MMM YYYY HH:mm") }), _jsx(Td, { children: moment(change.updated_at).format("DD MMM YYYY HH:mm") })] }, index));
                })) : (_jsx(Tr, { children: _jsx(Td, { colSpan: 7, children: "No change requests found" }) })) })] }));
}
