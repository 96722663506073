var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@/components/Button";
import Input from "@/components/Form/Input";
import { useAPI } from "@/hooks/useAPI";
import React, { useEffect } from "react";
export default function Step3(_a) {
    var form = _a.form, setForm = _a.setForm, prev = _a.prev, submit = _a.submit, isSubmitting = _a.isSubmitting;
    var hitAPI = useAPI();
    var _b = React.useState([]), types = _b[0], setTypes = _b[1];
    useEffect(function () {
        hitAPI("/utils/business-types")
            .then(function (response) { return response.json(); })
            .then(function (data) {
            setTypes(data.data);
        })
            .catch(function (error) {
            console.log(error);
        });
    }, []);
    var handleSubmit = function (e) {
        e.preventDefault();
        submit();
    };
    return (_jsxs("div", { className: "flex flex-col gap-4 px-6 lg:px-14 mb-6", children: [_jsxs("button", { onClick: prev, className: "flex items-center gap-2", children: [_jsx("svg", { width: "20", height: "20", viewBox: "0 0 25 25", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M8.325 13.5L13.925 19.1L12.5 20.5L4.5 12.5L12.5 4.5L13.925 5.9L8.325 11.5H20.5V13.5H8.325Z", fill: "#B5B5C3" }) }), _jsx("span", { className: "text-xs text-[#858585]", children: "Return" })] }), _jsxs("div", { className: "flex items-center justify-center", children: [_jsx("span", { className: "w-6 h-6 flex items-center justify-center border border-[#1BC5BD] rounded-full font-medium text-xs p-4 text-[#1BC5BD]", children: "1" }), _jsx("span", { className: "h-[1px] w-[3rem] bg-[#1BC5BD]" }), _jsx("span", { className: "w-6 h-6 flex items-center justify-center border border-[#1BC5BD] rounded-full font-medium text-xs p-4 text-[#1BC5BD]", children: "2" }), _jsx("span", { className: "h-[1px] w-[3rem] bg-[#1BC5BD]" }), _jsx("span", { className: "w-6 h-6 flex items-center justify-center bg-[#1BC5BD] rounded-full font-medium text-xs p-4 text-white", children: "3" })] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx("h1", { className: "text-xl text-[#123750]", children: "Sign Up" }), _jsx("p", { className: "text-xs font-medium text-[#858585]", children: "Tell us a little bit about your business" })] }), _jsxs("form", { onSubmit: handleSubmit, className: "flex flex-col gap-4", children: [_jsxs("div", { className: "flex flex-col gap-4 w-full", children: [_jsx(Input, { label: "Company Name", name: "name", required: true, value: form.merchant.name, onChange: function (e) {
                                    return setForm(function (form) {
                                        return __assign(__assign({}, form), { merchant: __assign(__assign({}, form.merchant), { name: e.target.value }) });
                                    });
                                } }), _jsxs("div", { className: "flex flex-col gap-2 w-full", children: [_jsxs("label", { className: "text-xs text-[#858585]", children: ["Select industry", _jsx("span", { className: "text-[#EB5757]", children: "*" })] }), _jsx("select", { name: "business_type_id", className: "bg-[#FAFAFA] border border-[#B5B5C3] py-3 px-4 rounded-[5rem] text-xs", required: true, value: form.merchant.business_type_id, onChange: function (e) {
                                            return setForm(function (form) {
                                                return __assign(__assign({}, form), { merchant: __assign(__assign({}, form.merchant), { business_type_id: parseInt(e.target.value) }) });
                                            });
                                        }, children: types.map(function (type) { return (_jsx("option", { value: type.id, children: type.name }, type.id)); }) })] }), _jsx(Input, { label: "I agree with Terms of Service and Privacy Policy", name: "tos", type: "checkbox", required: true, reversed: true })] }), _jsx(Button, { type: "submit", children: isSubmitting ? "Submitting..." : "Sign Up" })] })] }));
}
