import { atom } from "recoil";
var localStorageEffect = function (key, convert_json) {
    if (convert_json === void 0) { convert_json = false; }
    return function (_a) {
        var setSelf = _a.setSelf, onSet = _a.onSet;
        var savedValue = localStorage.getItem(key);
        if (savedValue != null) {
            setSelf(convert_json ? JSON.parse(savedValue) : savedValue);
        }
        onSet(function (newValue, _, isReset) {
            isReset
                ? localStorage.removeItem(key)
                : localStorage.setItem(key, convert_json ? JSON.stringify(newValue) : newValue);
        });
    };
};
export var merchantState = atom({
    key: "merchant",
    default: null,
    effects: [localStorageEffect("selected_merchant", true)],
});
export var merchantsState = atom({
    key: "merchants",
    default: [],
});
export var rolesState = atom({
    key: "roles",
    default: [],
    effects: [localStorageEffect("roles", true)],
});
