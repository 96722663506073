import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../Auth/state";
import { Link, useLocation } from "react-router-dom";
import { merchantState, merchantsState, rolesState } from "../Home/state";
import { Popover, Transition } from "@headlessui/react";
import Logo from "@/assets/beepplus.png";
import NoProfile from "@/assets/no-profile-picture-icon.png";
import { memo, useEffect, useState } from "react";
import { availableMenu } from "@/routes";
var Sidebar = memo(function () {
    var user = useRecoilValue(userState);
    var _a = useRecoilState(merchantState), merchant = _a[0], setMerchant = _a[1];
    var merchants = useRecoilValue(merchantsState);
    var roles = useRecoilValue(rolesState);
    var _b = useState(), currentRole = _b[0], setCurrentRole = _b[1];
    useEffect(function () {
        if (!merchant)
            return;
        setCurrentRole(roles.find(function (r) { return r.id == merchant.role_id; }));
    }, []);
    // const hitAPI = useAPI();
    // const [hasNotification, setHasNotification] = useState(false);
    // useEffect(() => {
    //     hitAPI("/merchant/notification/unread-count")
    //         .then((response) => response.json())
    //         .then((data: NotificationUnreadCountResponse) => {
    //             setHasNotification(data.data.count > 0);
    //         });
    // }, []);
    var location = useLocation();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "border-b border-b-[#E8E8ED33] pb-4", children: [_jsxs("div", { className: "flex items-center justify-between pl-8 pr-3 pt-8", children: [_jsx("img", { src: Logo, alt: "Logo", className: "w-[7rem]" }), _jsx("svg", { width: "14", height: "12", viewBox: "0 0 14 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M6 12L0 6L6 0L7.4 1.4L2.825 6L7.4 10.6L6 12ZM12.6 12L6.6 6L12.6 0L14 1.4L9.425 6L14 10.6L12.6 12Z", fill: "#1BC5BD" }) })] }), _jsxs("div", { className: "flex items-center justify-between gap-3 pl-0 pr-3 pt-8", children: [_jsxs(Popover, { className: "relative w-full", children: [_jsxs(Popover.Button, { className: "flex w-full items-center gap-3 justify-between rounded-e-2xl bg-[#1BC5BD33] bg-opacity-10 p-4 border border-r-0 border-[#1BC5BD33]", children: [_jsx("div", { className: "flex gap-3", children: merchant && (_jsxs(_Fragment, { children: [merchant.logo && (_jsx("img", { src: merchant.logo, alt: merchant.name, className: "h-[1.5rem] w-[1.5rem]" })), _jsx("p", { className: "line-clamp-1 text-ellipsis text-sm font-semibold text-white", children: merchant.name })] })) }), _jsx("div", { className: "relative", children: _jsx("svg", { width: "12", height: "8", viewBox: "0 0 12 8", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M10.59 0.590088L6 5.17009L1.41 0.590088L0 2.00009L6 8.00009L12 2.00009L10.59 0.590088Z", fill: "white" }) }) })] }), _jsx(Transition, { enter: "transition duration-100 ease-out", enterFrom: "transform scale-95 opacity-0", enterTo: "transform scale-100 opacity-100", leave: "transition duration-75 ease-out", leaveFrom: "transform scale-100 opacity-100", leaveTo: "transform scale-95 opacity-0", children: _jsx(Popover.Panel, { className: "absolute z-50 bg-white rounded-lg p-2 mt-2 ml-2 w-full", children: _jsx("ul", { className: "flex flex-col gap-1 p-2", children: merchants.map(function (m) {
                                                    return (_jsxs("li", { className: "flex gap-2 justify-between rounded-lg p-3 ".concat(merchant &&
                                                            m.id === merchant.id
                                                            ? "bg-[#BCDEE64D]"
                                                            : "", " hover:bg-[#BCDEE64D] cursor-pointer transition"), onClick: function () {
                                                            setMerchant(m);
                                                            window.location.reload();
                                                        }, children: [_jsxs("div", { className: "flex gap-3", children: [m.logo && (_jsx("img", { src: m.logo, alt: m.name, className: "h-[1.5rem] w-[1.5rem] rounded" })), _jsx("p", { className: "line-clamp-1 text-ellipsis text-sm font-semibold text-[#858585]", children: m.name })] }), merchant &&
                                                                m.id === merchant.id && (_jsx("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M8.6 14.6L15.65 7.55L14.25 6.15L8.6 11.8L5.75 8.95L4.35 10.35L8.6 14.6ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z", fill: "#1BC5BD" }) }))] }, m.id));
                                                }) }) }) })] }), _jsx("div", { className: "relative", children: _jsx(Link, { to: "/notifications", children: _jsx("button", { className: "rounded-lg bg-[#FFD875] p-2", children: _jsx("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M4 19V17H6V10C6 8.61667 6.41667 7.3875 7.25 6.3125C8.08333 5.2375 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.6458 2.72917 10.9375 2.4375C11.2292 2.14583 11.5833 2 12 2C12.4167 2 12.7708 2.14583 13.0625 2.4375C13.3542 2.72917 13.5 3.08333 13.5 3.5V4.2C14.8333 4.53333 15.9167 5.2375 16.75 6.3125C17.5833 7.3875 18 8.61667 18 10V17H20V19H4ZM12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1958 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22ZM8 17H16V10C16 8.9 15.6083 7.95833 14.825 7.175C14.0417 6.39167 13.1 6 12 6C10.9 6 9.95833 6.39167 9.175 7.175C8.39167 7.95833 8 8.9 8 10V17Z", fill: "#A87B0A" }) }) }) }) })] })] }), _jsxs("div", { children: [Object.keys(availableMenu).map(function (menu, idx) { return (_jsxs("div", { className: "flex flex-col gap-3", children: [_jsx("p", { className: "pl-7 pr-3 pt-5 text-xs text-[#B5B5C3] font-semibold", children: menu.toUpperCase() }), _jsx("div", { children: availableMenu[menu].map(function (item, idx) {
                                    if (!currentRole)
                                        return null;
                                    if (item.allowed_role.indexOf(currentRole === null || currentRole === void 0 ? void 0 : currentRole.id) === -1)
                                        return null;
                                    return (_jsx(Link, { className: "group pl-7 pr-6 py-3 flex items-center justify-between ".concat(location.pathname === item.link ||
                                            (!item.exact_match &&
                                                location.pathname.includes(item.link))
                                            ? "text-[#1BC5BD] bg-[#CBEDEC]"
                                            : "text-white bg-inherit", " hover:text-[#1BC5BD]"), to: item.link, children: _jsxs("div", { className: "flex gap-3", children: [_jsx(item.icon, { className: "group-hover:fill-[#1BC5BD] ".concat(location.pathname ===
                                                        item.link ||
                                                        (!item.exact_match &&
                                                            location.pathname.includes(item.link))
                                                        ? "fill-[#1BC5BD]"
                                                        : "fill-[#DCF1FF]") }), _jsx("p", { className: "text-sm", children: item.title })] }) }, idx));
                                }) })] }, idx)); }), _jsxs("div", { className: "flex flex-col gap-3 border-b border-b-[#E8E8ED33] pb-4", children: [_jsx("p", { className: "pl-7 pr-3 pt-5 text-xs text-[#B5B5C3] font-semibold", children: "ACCOUNT" }), _jsxs("div", { className: "pl-7 pr-3 flex items-center gap-3", children: [_jsx("img", { src: NoProfile, className: "w-[3rem] h-[3rem] rounded-full object-cover" }), _jsxs("div", { className: "text-white text-sm", children: [_jsx("p", { className: "font-semibold line-clamp-1", children: user === null || user === void 0 ? void 0 : user.name }), merchant && (_jsxs("p", { children: [currentRole === null || currentRole === void 0 ? void 0 : currentRole.name, " at ", merchant.name] }))] })] })] }), _jsx("div", { className: "py-4", children: _jsx("ul", { children: _jsxs(Link, { to: "/auth/logout", className: "pl-7 pr-6 flex items-center justify-between", children: [_jsx("p", { className: "text-white text-sm", children: "Log Out" }), _jsx("button", { className: "bg-[#1BC5BD] rounded-lg p-2 text-sm text-white", children: _jsx("svg", { width: "20", height: "14", viewBox: "0 0 20 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M13 0L11.59 1.41L16.17 6H0V8H16.17L11.58 12.59L13 14L20 7L13 0Z", fill: "#DCF1FF" }) }) })] }) }) })] })] }));
});
export default Sidebar;
