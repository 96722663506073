var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ArrowDown from "../Icons/ArrowDown";
import ArrowLeft from "../Icons/ArrowLeft";
import ArrowRight from "../Icons/ArrowRight";
function remapPageNumber(current, total) {
    // https://stackoverflow.com/a/11274294
    var startPage = current < 5 ? 1 : current - 4;
    var endPage = 8 + startPage;
    endPage = total < endPage ? total : endPage;
    var diff = startPage - endPage + 8;
    startPage -= startPage - diff > 0 ? diff : 0;
    // Generate the page numbers
    var pages = [];
    // Map the page numbers
    if (startPage > 1)
        pages = [1, null];
    for (var i = startPage; i <= endPage; i++)
        pages.push(i);
    if (endPage < total)
        pages = __spreadArray(__spreadArray([], pages, true), [null, total], false);
    // Return the page numbers
    return pages;
}
export default function Table(_a) {
    var pagination = _a.pagination, children = _a.children, props = __rest(_a, ["pagination", "children"]);
    if (!pagination)
        return (_jsx("table", __assign({ className: "w-full" }, props, { children: children })));
    return (_jsxs("div", { children: [_jsx("table", __assign({ className: "w-full" }, props, { children: children })), _jsxs("div", { className: "m-auto mt-10 flex w-min justify-center overflow-hidden whitespace-nowrap rounded-2xl bg-[#DCF1FF] text-[#123750CC] text-xs", children: [_jsxs("span", { onClick: function () {
                            return pagination.changePage(pagination.current_page - 1);
                        }, className: "\n                    ".concat(pagination.current_page === 1
                            ? "cursor-not-allowed text-[#DADADA]"
                            : "cursor-pointer", " \n                    transition hover:bg-[#1BC5BD] hover:text-white flex items-center gap-2 px-4 py-3\n                "), children: [" ", _jsx(ArrowLeft, { disabled: pagination.current_page === 1 }), " ", "Previous", " "] }), _jsx("div", { className: "flex items-center", children: remapPageNumber(pagination.current_page, pagination.last_page).map(function (page) {
                            if (page === null)
                                return _jsx("span", { className: "px-3 py-3", children: "..." });
                            return (_jsx("span", { onClick: function () { return pagination.changePage(page); }, className: "\n                                ".concat(pagination.current_page === page
                                    ? "bg-[#1BC5BD] text-white"
                                    : "hover:bg-[#1BC5BD] hover:text-white", "\n                                cursor-pointer px-3 py-3 transition\n                            "), children: page }, page));
                        }) }), _jsxs("span", { onClick: function () {
                            return pagination.changePage(pagination.current_page + 1);
                        }, className: "\n                    ".concat(pagination.current_page === pagination.last_page
                            ? "cursor-not-allowed text-[#DADADA]"
                            : "cursor-pointer", " \n                    transition hover:bg-[#1BC5BD] hover:text-white flex items-center gap-2 px-4 py-3\n                "), children: ["Next", " ", _jsx(ArrowRight, { disabled: pagination.current_page === pagination.last_page })] }), _jsxs("span", { className: "bg-[#1BC5BD] flex gap-2 items-center px-4 py-3 text-white", children: [pagination.per_page, " per page ", _jsx(ArrowDown, {})] })] })] }));
}
