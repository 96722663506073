var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DashboardLayout from "../Layout/DashboardLayout";
import { useState } from "react";
import { useAPI } from "@/hooks/useAPI";
import toast from "react-hot-toast";
export default function AccountSettingsPage() {
    var _a = useState("security"), activeTab = _a[0], setActiveTab = _a[1];
    return (_jsx(_Fragment, { children: _jsx(DashboardLayout, { title: "Settings", children: _jsxs("div", { className: "bg-white rounded-2xl py-6", style: {
                    boxShadow: "2px 4px 6px 0px rgba(72, 83, 73, 0.04)",
                }, children: [_jsx("div", { className: "flex items-center gap-4 justify-between px-5", children: _jsx("h1", { className: "text-xl text-[#123750]", children: "Settings" }) }), _jsx("div", { className: "flex gap-2 px-5 mt-2", children: _jsx("button", { className: "bg-[#FFAE5033] text-[#FFAE50] px-4 py-3 text-xs rounded-lg font-medium", style: {
                                boxShadow: "2px 4px 6.1px 0px rgba(72, 83, 73, 0.04)",
                            }, children: "Security Preferences" }) }), _jsx("div", { className: "mt-4", children: {
                            // personal: <PersonalInformation />,
                            security: _jsx(SecurityPreferences, {}),
                        }[activeTab] })] }) }) }));
}
var SecurityPreferences = function () {
    var hitAPI = useAPI();
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var handleChangePassword = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    setIsLoading(true);
                    return [4 /*yield*/, hitAPI("/auth/change-password", "POST", __assign({}, Object.fromEntries(new FormData(e.currentTarget))))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (response.ok)
                        toast.success(data.message);
                    else
                        toast.error(data.message);
                    setIsLoading(false);
                    e.currentTarget.reset();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "px-5 py-4", children: [_jsx("p", { className: "font-semibold text-sm text-[#123750]", children: "Password" }), _jsx("p", { className: "text-xs text-[#858585]", children: "Change your password" })] }), _jsxs("form", { onSubmit: handleChangePassword, children: [_jsxs("div", { className: "flex flex-col gap-4 text-[#858585] px-5 max-w-[75%]", children: [_jsxs("div", { className: "grid grid-cols-2 justify-center items-center gap-4", children: [_jsxs("label", { className: "font-medium text-xs", children: ["Current password", _jsx("span", { className: "text-[#EB5757]", children: "*" })] }), _jsx("input", { type: "password", name: "current_password", className: "border border-[#B5B5C3] rounded-sm text-xs py-2 px-4", placeholder: "Current password", required: true })] }), _jsxs("div", { className: "grid grid-cols-2 justify-center items-center gap-4", children: [_jsx("label", { className: "font-medium text-xs", children: "New password" }), _jsx("input", { type: "password", name: "new_password", className: "border border-[#B5B5C3] rounded-sm text-xs py-2 px-4", placeholder: "New password", required: true })] }), _jsxs("div", { className: "grid grid-cols-2 justify-center items-center gap-4", children: [_jsx("label", { className: "font-medium text-xs", children: "Confirm new password" }), _jsx("input", { type: "password", name: "new_password_confirmation", className: "border border-[#B5B5C3] rounded-sm text-xs py-2 px-4", placeholder: "Confirm new password", required: true })] })] }), _jsx("button", { type: "submit", className: "mx-5 mt-6 py-3 px-16 font-medium rounded-sm bg-[#CBEDEC] border border-[#1BC5BD] text-[#1BC5BD] text-xs", children: isLoading ? "Loading..." : "Change Password" })] })] }));
};
