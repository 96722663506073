var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@/components/Button";
import Input from "@/components/Form/Input";
import { useAPI } from "@/hooks/useAPI";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function ForgotPassword() {
    var _this = this;
    var navigate = useNavigate();
    var hitAPI = useAPI();
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var handleSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var email, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    setIsLoading(true);
                    email = e.currentTarget.email.value;
                    return [4 /*yield*/, hitAPI("/auth/otp/request", "POST", { email: email })];
                case 1:
                    response = _a.sent();
                    if (response.status === 200)
                        navigate(
                        // Navigate to the reset password page with the email as a query parameter
                        "/auth/forgot-password/reset?email=".concat(email));
                    else {
                        alert("Something went wrong");
                        setIsLoading(false);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "flex flex-col gap-8 px-6 lg:px-14 mb-6", children: [_jsxs("div", { className: "flex items-center justify-center", children: [_jsx("span", { className: "w-6 h-6 flex items-center justify-center bg-[#1BC5BD] rounded-full font-medium text-xs p-4 text-white", children: "1" }), _jsx("span", { className: "h-[1px] w-[3rem] bg-[#1BC5BD]" }), _jsx("span", { className: "w-6 h-6 flex items-center justify-center border border-[#1BC5BD] rounded-full font-medium text-xs p-4 text-[#1BC5BD]", children: "2" })] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx("h1", { className: "text-xl", children: "Forgot Password" }), _jsx("p", { className: "text-xs font-medium text-[#858585]", children: "We will send an email with the verification code. If you don't see it, please check your spam folder." })] }), _jsxs("form", { onSubmit: handleSubmit, className: "flex flex-col gap-4", children: [_jsx("div", { className: "flex flex-col gap-2 w-full", children: _jsx(Input, { label: "Email", name: "email", required: true }) }), _jsx(Button, { type: "submit", children: isLoading ? "Loading..." : "Next" })] })] }));
}
