var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Table from "@/components/Table/Table";
import Thead from "@/components/Table/Thead";
import Tr from "@/components/Table/Tr";
import Th from "@/components/Table/Th";
import Tbody from "@/components/Table/Tbody";
import Td from "@/components/Table/Td";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { merchantState, rolesState } from "@/views/Home/state";
import { useAPI } from "@/hooks/useAPI";
import toast from "react-hot-toast";
import TripleVerticalDot from "../Icons/TripleVerticalDot";
import { Dialog, Popover, Transition } from "@headlessui/react";
import CloseIcon from "../Icons/CloseIcon";
function drawStatus(status) {
    return {
        ACTIVE: (_jsx("span", { className: "bg-[#1BC5BD33] bg-opacity-20 text-[#1BC5BD] px-3 py-1 rounded-md", children: "ACTIVE" })),
        INACTIVE: (_jsx("span", { className: "bg-[#FFAE5033] bg-opacity-20 text-[#FFAE50] px-3 py-1 rounded-md", children: "INACTIVE" })),
        SUSPENDED: (_jsx("span", { className: "bg-[#FF6A6533] bg-opacity-20 text-[#FF6A65] px-3 py-1 rounded-md", children: "SUSPENDED" })),
    }[status];
}
export default function StaffsTable() {
    var _this = this;
    var hitAPI = useAPI();
    var roles = useRecoilValue(rolesState);
    var merchant = useRecoilValue(merchantState);
    var _a = useState(), currentlyEditing = _a[0], setCurrentlyEditing = _a[1];
    var _b = useState(false), modalOpen = _b[0], setModalOpen = _b[1];
    var _c = useState({
        data: [],
        meta: {
            current_page: 1,
            last_page: 1,
            per_page: 15,
            total: 0,
        },
    }), data = _c[0], setData = _c[1];
    var fetchData = function () { return __awaiter(_this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!merchant)
                        return [2 /*return*/];
                    return [4 /*yield*/, hitAPI("/merchant/".concat(merchant.id, "/staffs"))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (response.ok)
                        setData(data.data);
                    else
                        toast.error(data.message);
                    return [2 /*return*/];
            }
        });
    }); };
    var editStaff = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var toastID, response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!merchant || !currentlyEditing)
                        return [2 /*return*/];
                    toastID = toast.loading("Submitting data...");
                    return [4 /*yield*/, hitAPI("/merchant/".concat(merchant.id, "/staffs/").concat(currentlyEditing.id), "POST", {
                            name: currentlyEditing.user.name,
                            email: currentlyEditing.user.email,
                            mobile_number: currentlyEditing.user.phone_number,
                            role_id: e.currentTarget.role_id.value,
                            status: e.currentTarget.status.value,
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (response.ok) {
                        toast.success("Staff updated successfully", { id: toastID });
                        fetchData();
                    }
                    else {
                        toast.error(data.message, { id: toastID });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchData();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs(Table, { children: [_jsx(Thead, { children: _jsxs(Tr, { children: [_jsx(Th, { children: "Name" }), _jsx(Th, { children: "Email" }), _jsx(Th, { children: "Phone" }), _jsx(Th, { children: "Role" }), _jsx(Th, { children: "Status" }), _jsx(Th, { children: "Action" })] }) }), _jsx(Tbody, { children: data.data.map(function (staff) {
                            return (_jsxs(Tr, { children: [_jsx(Td, { children: staff.user.name }), _jsx(Td, { children: staff.user.email }), _jsx(Td, { children: staff.user.phone_number }), _jsx(Td, { children: staff.role.name }), _jsx(Td, { children: drawStatus(staff.status) }), _jsx(Td, { children: _jsxs(Popover, { className: "relative w-full", children: [_jsx(Popover.Button, { className: "flex pl-6 items-center justify-center", children: _jsx(TripleVerticalDot, {}) }), _jsx(Transition, { enter: "transition duration-100 ease-out", enterFrom: "transform scale-95 opacity-0", enterTo: "transform scale-100 opacity-100", leave: "transition duration-75 ease-out", leaveFrom: "transform scale-100 opacity-100", leaveTo: "transform scale-95 opacity-0", children: _jsx(Popover.Panel, { className: "absolute z-50 bg-white rounded-lg p-2 mt-2 w-full border border-[#DCF1FF]", style: {
                                                            boxShadow: "0px 4px 24px 0px #0000000A",
                                                        }, children: _jsx("ul", { className: "flex flex-col gap-1 p-2", children: _jsx("li", { onClick: function () {
                                                                    setModalOpen(true);
                                                                    setCurrentlyEditing(staff);
                                                                }, className: "text-[#858585] hover:text-[#1BC5BD] font-medium p-2 cursor-pointer", children: "EDIT" }) }) }) })] }) })] }, staff.id));
                        }) })] }), _jsxs(Dialog, { open: modalOpen, onClose: function () { return setModalOpen(false); }, className: "relative z-50", children: [_jsx("div", { className: "fixed inset-0 bg-black/30", "aria-hidden": "true" }), _jsx("div", { className: "fixed inset-0 flex w-screen items-center justify-center p-4", children: _jsxs(Dialog.Panel, { className: "mx-auto max-w-sm rounded-2xl bg-white px-6 py-9 my-8 min-w-[40%] overflow-y-auto", children: [_jsxs("div", { className: "flex flex-col gap-3", children: [_jsxs("div", { className: "flex items-center justify-between", children: [_jsxs("span", { className: "flex items-center gap-4 text-[#858585]", children: [_jsx("p", { className: "font-medium", children: currentlyEditing === null || currentlyEditing === void 0 ? void 0 : currentlyEditing.user.name }), currentlyEditing &&
                                                            drawStatus(currentlyEditing.status)] }), _jsx("button", { onClick: function () { return setModalOpen(false); }, children: _jsx(CloseIcon, {}) })] }), _jsx("span", { className: "text-sm text-[#858585]", children: currentlyEditing === null || currentlyEditing === void 0 ? void 0 : currentlyEditing.user.email }), _jsx("span", { className: "text-sm text-[#858585]", children: currentlyEditing === null || currentlyEditing === void 0 ? void 0 : currentlyEditing.user.phone_number })] }), _jsxs("form", { onSubmit: editStaff, className: "flex flex-col gap-4 mt-2", children: [_jsx("select", { name: "role_id", className: "border border-[#B5B5C3] rounded-md p-2 text-xs text-[#858585]", defaultValue: currentlyEditing === null || currentlyEditing === void 0 ? void 0 : currentlyEditing.role.id, children: roles.map(function (role) { return (_jsx("option", { value: role.id, children: role.name }, role.id)); }) }), _jsxs("select", { name: "status", className: "border border-[#B5B5C3] rounded-md p-2 text-xs text-[#858585]", defaultValue: (currentlyEditing === null || currentlyEditing === void 0 ? void 0 : currentlyEditing.status) === "ACTIVE"
                                                ? 1
                                                : 0, children: [_jsx("option", { value: 1, children: "Active" }), _jsx("option", { value: 0, children: "Inactive" })] }), _jsx("button", { type: "submit", className: "border border-[#1BC5BD] text-[#1BC5BD] bg-[#CBEDEC] py-3 px-8 font-medium rounded-sm", children: "Save" }), _jsx("button", { type: "submit", className: "px-8 font-medium text-[#1BC5BD]", children: "Cancel" })] })] }) })] })] }));
}
