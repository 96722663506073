var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import DashboardLayout from "@/views/Layout/DashboardLayout";
import { useRecoilValue } from "recoil";
import { userState } from "../Auth/state";
import { useAPI } from "@/hooks/useAPI";
import { merchantState } from "./state";
import Transactions from "@/components/Tables/Transactions";
import DashboardChart from "@/components/Chart/DashboardChart";
import { Roles } from "@/enums/Roles";
import { useNavigate } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import CanAccess from "@/components/CanAccess";
var Home = function () {
    var navigate = useNavigate();
    var merchant = useRecoilValue(merchantState);
    var user = useRecoilValue(userState);
    var hitAPI = useAPI();
    // Set initial start date to be a week ago
    var currentDate = new Date();
    var _a = useState(new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000)), startDate = _a[0], setStartDate = _a[1];
    var _b = useState([
        "transactions",
        "sales",
    ]), selectedData = _b[0], setSelectedData = _b[1];
    var _c = useState(null), statistics = _c[0], setStatistics = _c[1];
    var processDataSelection = function (key) {
        if (selectedData.includes(key)) {
            setSelectedData(selectedData.filter(function (data) { return data !== key; }));
        }
        else {
            setSelectedData(__spreadArray(__spreadArray([], selectedData, true), [key], false));
        }
    };
    var getStatistics = function (merchant) { return __awaiter(void 0, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, hitAPI("/merchant/".concat(merchant.id, "/statistics?start_date=").concat(startDate.toLocaleDateString("sv"), "&end_date=").concat(currentDate.toLocaleDateString("sv")))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    return [2 /*return*/, data.data];
            }
        });
    }); };
    var getData = function (merchant) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = setStatistics;
                    return [4 /*yield*/, getStatistics(merchant)];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        merchant && getData(merchant);
    }, [startDate]);
    useEffect(function () {
        merchant &&
            merchant.role_id === Roles.Staff &&
            navigate("/transactions");
    }, []);
    return (_jsxs(DashboardLayout, { title: "Welcome back, ".concat(user === null || user === void 0 ? void 0 : user.name), children: [_jsx(CanAccess, { allowed_level: [
                    Roles.Admin,
                    Roles.Manager,
                    Roles.Owner,
                    Roles.Finance,
                ], children: _jsxs("div", { className: "bg-white rounded-2xl py-6", style: {
                        boxShadow: "2px 4px 6px 0px rgba(72, 83, 73, 0.04)",
                    }, children: [_jsxs("div", { className: "flex justify-between px-5", children: [_jsx("h1", { className: "text-xl pb-4 text-[#123750]", children: "Statistics" }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsxs(Popover, { className: "relative w-full", children: [_jsx(Popover.Button, { className: "px-4 py-3 border border-[#E8E8ED] rounded-lg text-[#737373]", children: "Change View" }), _jsx(Transition, { enter: "transition duration-100 ease-out", enterFrom: "transform scale-95 opacity-0", enterTo: "transform scale-100 opacity-100", leave: "transition duration-75 ease-out", leaveFrom: "transform scale-100 opacity-100", leaveTo: "transform scale-95 opacity-0", children: _jsxs(Popover.Panel, { className: "absolute z-50 bg-white rounded-lg py-4 px-8 mt-2 border border-[#DCF1FF] space-y-2", style: {
                                                            boxShadow: "0px 4px 24px 0px #0000000A",
                                                        }, children: [_jsxs("div", { className: "flex items-center gap-2", children: [_jsx("input", { type: "checkbox", id: "transactions", disabled: selectedData.length >= 2 &&
                                                                            !selectedData.includes("transactions"), checked: selectedData.includes("transactions"), onClick: function () {
                                                                            return processDataSelection("transactions");
                                                                        } }), _jsx("label", { htmlFor: "transactions", className: "text-[#858585] hover:text-[#1BC5BD] font-medium cursor-pointer", children: "Total Transactions" })] }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx("input", { type: "checkbox", id: "sales", disabled: selectedData.length >= 2 &&
                                                                            !selectedData.includes("sales"), checked: selectedData.includes("sales"), onClick: function () {
                                                                            return processDataSelection("sales");
                                                                        } }), _jsx("label", { htmlFor: "sales", className: "text-[#858585] hover:text-[#1BC5BD] font-medium cursor-pointer", children: "Total Sales" })] }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx("input", { type: "checkbox", id: "mdr", disabled: selectedData.length >= 2 &&
                                                                            !selectedData.includes("mdr"), checked: selectedData.includes("mdr"), onClick: function () {
                                                                            return processDataSelection("mdr");
                                                                        } }), _jsx("label", { htmlFor: "mdr", className: "text-[#858585] hover:text-[#1BC5BD] font-medium cursor-pointer", children: "Total MDR" })] }), _jsxs("div", { className: "flex items-center gap-2", children: [_jsx("input", { type: "checkbox", id: "net_settlement", disabled: selectedData.length >= 2 &&
                                                                            !selectedData.includes("net_settlement"), checked: selectedData.includes("net_settlement"), onClick: function () {
                                                                            return processDataSelection("net_settlement");
                                                                        } }), _jsx("label", { htmlFor: "net_settlement", className: "text-[#858585] hover:text-[#1BC5BD] font-medium cursor-pointer", children: "Net Settlement" })] })] }) })] }), _jsxs("select", { onChange: function (e) {
                                                var value = e.target.value;
                                                var currentDate = new Date();
                                                var newStartDate;
                                                switch (value) {
                                                    case "1 week":
                                                        newStartDate = new Date(currentDate.getTime() -
                                                            7 * 24 * 60 * 60 * 1000);
                                                        break;
                                                    case "1 month":
                                                        newStartDate = new Date(currentDate.getTime() -
                                                            30 * 24 * 60 * 60 * 1000);
                                                        break;
                                                    case "3 months":
                                                        newStartDate = new Date(currentDate.getTime() -
                                                            90 * 24 * 60 * 60 * 1000);
                                                        break;
                                                    default:
                                                        newStartDate = new Date(currentDate.getTime() -
                                                            7 * 24 * 60 * 60 * 1000);
                                                        break;
                                                }
                                                setStartDate(newStartDate);
                                            }, className: "px-4 py-3 border border-[#E8E8ED] rounded-lg text-[#737373]", children: [_jsx("option", { value: "1 week", children: "Last 7 days" }), _jsx("option", { value: "1 month", children: "Last 30 days" }), _jsx("option", { value: "3 months", children: "Last 90 days" })] })] })] }), _jsx("div", { className: "grid grid-cols-2 gap-2 px-5", children: statistics && (_jsxs(_Fragment, { children: [selectedData.includes("transactions") && (_jsx(DashboardChart, { data: statistics.transactions, title: "Total Transactions" })), selectedData.includes("sales") && (_jsx(DashboardChart, { data: statistics.revenue, title: "Total Sales", with_dollar_sign: true })), selectedData.includes("mdr") && (_jsx(DashboardChart, { data: statistics.mdr, title: "Total MDR", with_dollar_sign: true })), selectedData.includes("net_settlement") && (_jsx(DashboardChart, { data: statistics.net_settlement, title: "Net Settlement", with_dollar_sign: true }))] })) })] }) }), _jsxs("div", { className: "bg-white rounded-2xl py-6", style: {
                    boxShadow: "2px 4px 6px 0px rgba(72, 83, 73, 0.04)",
                }, children: [_jsx("h1", { className: "text-xl px-5 pb-4 text-[#123750]", children: "Recent Transactions" }), _jsx(Transactions, { count: 10, shouldPaginate: false, filters: {
                            start_date: "",
                            end_date: "",
                            channel: [1, 2, 3, 4, 5, 6],
                            status: [1],
                        } })] })] }));
};
export default Home;
