var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Settlements from "@/components/Tables/Settlements";
import DashboardLayout from "../Layout/DashboardLayout";
import { useRecoilValue } from "recoil";
import { merchantState } from "@/views/Home/state";
import { useAPI } from "@/hooks/useAPI";
export default function SettlementsPage() {
    var _this = this;
    var hitAPI = useAPI();
    var merchant = useRecoilValue(merchantState);
    var exportData = function () { return __awaiter(_this, void 0, void 0, function () {
        var response, anchor, objectUrl, _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!merchant)
                        return [2 /*return*/];
                    return [4 /*yield*/, hitAPI("/merchant/".concat(merchant.id, "/payouts/export"))];
                case 1:
                    response = _c.sent();
                    anchor = document.createElement("a");
                    _b = (_a = window.URL).createObjectURL;
                    return [4 /*yield*/, response.blob()];
                case 2:
                    objectUrl = _b.apply(_a, [_c.sent()]);
                    anchor.href = objectUrl;
                    anchor.download = "payouts.xlsx";
                    anchor.click();
                    // Remove the URL after download
                    window.URL.revokeObjectURL(objectUrl);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(DashboardLayout, { title: "Settlements", children: _jsxs("div", { className: "bg-white rounded-2xl py-6", style: {
                boxShadow: "2px 4px 6px 0px rgba(72, 83, 73, 0.04)",
            }, children: [_jsx("h1", { className: "text-xl px-5 text-[#123750]", children: "Settlements" }), _jsxs("div", { className: "flex items-center justify-between px-6 py-4", children: [_jsx("div", { children: _jsx("input", { type: "text", placeholder: "Search", className: "border-b" }) }), _jsxs("div", { className: "flex gap-3", children: [_jsxs("button", { className: "flex items-center gap-2 text-[#123750CC] px-4 py-3 border border-[#E9ECF5] rounded-md", children: [_jsx("svg", { width: "15", height: "10", viewBox: "0 0 15 10", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M6 9.5V8H9V9.5H6ZM3 5.75V4.25H12V5.75H3ZM0.75 2V0.5H14.25V2H0.75Z", fill: "#123750", "fill-opacity": "0.8" }) }), "Filters"] }), _jsxs("button", { onClick: exportData, className: "flex items-center gap-2 text-[#123750CC] px-4 py-3 border border-[#E9ECF5] rounded-md", children: [_jsx("svg", { width: "13", height: "12", viewBox: "0 0 13 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M6.5 9L2.75 5.25L3.8 4.1625L5.75 6.1125V0H7.25V6.1125L9.2 4.1625L10.25 5.25L6.5 9ZM2 12C1.5875 12 1.23438 11.8531 0.940625 11.5594C0.646875 11.2656 0.5 10.9125 0.5 10.5V8.25H2V10.5H11V8.25H12.5V10.5C12.5 10.9125 12.3531 11.2656 12.0594 11.5594C11.7656 11.8531 11.4125 12 11 12H2Z", fill: "#123750", "fill-opacity": "0.8" }) }), "Export"] })] })] }), _jsx(Settlements, {})] }) }));
}
