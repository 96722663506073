import { jsx as _jsx } from "react/jsx-runtime";
export default function TransactionStatusCapsule(_a) {
    var status = _a.status;
    var styles = {
        PENDING: "bg-[#FFAE5033] text-[#FFAE50]",
        PAID: "bg-[#1BC5BD33] text-[#1BC5BD]",
        FAILED: "bg-[#FF6A6533] text-[#FF6A65]",
        REFUND: "bg-[#FF6A6533] text-[#FF6A65]",
        EXPIRED: "bg-[#FF6A6533] text-[#FF6A65]",
    };
    return (_jsx("span", { className: "bg-opacity-20 px-3 py-1 rounded-md text-xs ".concat(styles[status]), children: status === "PAID" ? "SUCCESS" : status }));
}
