import { atom } from "recoil";
import Cookies from "universal-cookie";
var cookies = new Cookies(null, { path: "/", sameSite: true });
var getAndStoreInCookie = function (key) {
    return function (_a) {
        var setSelf = _a.setSelf, onSet = _a.onSet;
        var savedValue = cookies.get(key);
        if (savedValue != null) {
            setSelf(savedValue);
        }
        onSet(function (newValue, _, isReset) {
            isReset ? cookies.remove(key) : cookies.set(key, newValue);
        });
    };
};
export var userState = atom({
    key: "user",
    default: null,
});
export var accessTokenState = atom({
    key: "accessToken",
    default: "",
    effects: [getAndStoreInCookie("access_token")],
});
export var haveCheckedAuthState = atom({
    key: "haveCheckedAuth",
    default: false,
});
