var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@/components/Button";
import { useState } from "react";
import { useAPI } from "@/hooks/useAPI";
import toast from "react-hot-toast";
export default function Step2(_a) {
    var _this = this;
    var form = _a.form, setForm = _a.setForm, prev = _a.prev, next = _a.next;
    var hitAPI = useAPI();
    var _b = useState(form.user.otp
        ? form.user.otp.toString().split("")
        : ["", "", "", "", "", ""]), completeOTP = _b[0], setCompleteOTP = _b[1];
    // TODO: Resend OTP
    // const [timeToResend, setTimeToResend] = React.useState(30);
    // React.useEffect(() => {
    //     if (timeToResend === 0) return;
    //     const interval = setInterval(() => {
    //         setTimeToResend((prev) => {
    //             if (prev === 0) clearInterval(interval);
    //             return prev - 1;
    //         });
    //     }, 1000);
    //     return () => clearInterval(interval);
    // }, [timeToResend]);
    var handleSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    return [4 /*yield*/, hitAPI("/auth/otp/verify", "POST", {
                            phone: form.user.phone_number,
                            otp: parseInt(completeOTP.join("")),
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (response.ok) {
                        setForm(function (prev) {
                            return __assign(__assign({}, prev), { user: __assign(__assign({}, prev.user), { otp: parseInt(completeOTP.join("")) }) });
                        });
                        next();
                    }
                    else
                        toast.error(data.message);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "flex flex-col gap-8 px-6 lg:px-14 mb-6", children: [_jsxs("button", { onClick: prev, className: "flex items-center gap-2", children: [_jsx("svg", { width: "20", height: "20", viewBox: "0 0 25 25", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: _jsx("path", { d: "M8.325 13.5L13.925 19.1L12.5 20.5L4.5 12.5L12.5 4.5L13.925 5.9L8.325 11.5H20.5V13.5H8.325Z", fill: "#B5B5C3" }) }), _jsx("span", { className: "text-xs text-[#858585]", children: "Return" })] }), _jsxs("div", { className: "flex items-center justify-center", children: [_jsx("span", { className: "w-6 h-6 flex items-center justify-center border border-[#1BC5BD] rounded-full font-medium text-xs p-4 text-[#1BC5BD]", children: "1" }), _jsx("span", { className: "h-[1px] w-[3rem] bg-[#1BC5BD]" }), _jsx("span", { className: "w-6 h-6 flex items-center justify-center bg-[#1BC5BD] rounded-full font-medium text-xs p-4 text-white", children: "2" }), _jsx("span", { className: "h-[1px] w-[3rem] bg-[#1BC5BD]" }), _jsx("span", { className: "w-6 h-6 flex items-center justify-center border border-[#1BC5BD] rounded-full font-medium text-xs p-4 text-[#1BC5BD]", children: "3" })] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx("h1", { className: "text-xl text-[#123750]", children: "Sign Up" }), _jsx("h3", { className: "text-[#123750]", children: "We sent an OTP" }), _jsx("p", { className: "text-xs font-medium text-[#858585]", children: "Please enter your one time password (OTP) to verify your mobile number." })] }), _jsxs("form", { onSubmit: handleSubmit, className: "flex flex-col gap-4", children: [_jsx("div", { className: "flex items-center justify-center gap-2", children: [0, 1, 2, 3, 4, 5].map(function (i) { return (_jsx("input", { maxLength: 1, type: "text", className: "h-10 w-10 border focus:outline-[#0064A7] p-2 rounded-md text-xs text-center", autoFocus: i === 0, value: completeOTP[i], onChange: function (e) {
                                setCompleteOTP(function (prev) {
                                    var newOTP = __spreadArray([], prev, true);
                                    newOTP[i] = e.target.value;
                                    return newOTP;
                                });
                            }, onKeyUp: function (e) {
                                if (e.key === "Backspace" ||
                                    e.key === "Delete" ||
                                    e.key === "ArrowLeft") {
                                    if (i === 0)
                                        return;
                                    e.currentTarget
                                        .previousSibling.focus();
                                }
                                else {
                                    if (i === 5)
                                        return;
                                    e.currentTarget
                                        .nextSibling.focus();
                                }
                            } }, i)); }) }), _jsx(Button, { type: "submit", children: "Next" })] })] }));
}
