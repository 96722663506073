var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DashboardLayout from "../Layout/DashboardLayout";
import { useEffect, useState } from "react";
import { useAPI } from "@/hooks/useAPI";
import { useRecoilValue } from "recoil";
import { merchantState } from "../Home/state";
import moment from "moment";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import CanAccess from "@/components/CanAccess";
import { Roles } from "@/enums/Roles";
export default function RefundDetailPage() {
    var _this = this;
    var _a, _b;
    var hitAPI = useAPI();
    var id = useParams().id;
    var merchant = useRecoilValue(merchantState);
    var _c = useState(), data = _c[0], setData = _c[1];
    var getData = function (merchant) { return __awaiter(_this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, hitAPI("/merchant/".concat(merchant.id, "/refunds/").concat(id))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    setData(data.data);
                    return [2 /*return*/];
            }
        });
    }); };
    var approveRefund = function () { return __awaiter(_this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!merchant)
                        return [2 /*return*/];
                    return [4 /*yield*/, hitAPI("/merchant/".concat(merchant.id, "/refunds/").concat(id), "PUT", {
                            approve: 1,
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (response.status === 200) {
                        toast.success("Thanks for confirming this request. Refund will be processed by Beep team ASAP.");
                        // Reload data on success
                        getData(merchant);
                    }
                    else
                        toast.error(data.message);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        merchant && getData(merchant);
    }, []);
    if (!data)
        return null;
    return (_jsx(DashboardLayout, { title: "Refund for Transaction #".concat(data.sale.id), children: _jsx("div", { className: "bg-white rounded-2xl py-6", style: {
                boxShadow: "2px 4px 6px 0px rgba(72, 83, 73, 0.04)",
            }, children: _jsxs("div", { className: "flex flex-col px-5", children: [_jsx("div", { className: "bg-[#F8FAFD]", children: _jsx("p", { className: "font-semibold px-4 py-3 text-[#123750]", children: "Refund Details" }) }), _jsxs("div", { className: "grid grid-cols-2 my-2 text-sm text-[#858585] gap-4", children: [_jsxs("div", { className: "flex flex-col gap-2", children: [_jsxs("div", { className: "flex gap-2 justify-between", children: [_jsx("p", { children: "Requested at:" }), _jsx("span", { className: "font-medium", children: moment(data.created_at).format("DD/MM/YYYY HH:mm a") })] }), _jsxs("div", { className: "flex gap-2 justify-between", children: [_jsx("p", { children: "Amount:" }), _jsxs("span", { className: "font-medium", children: ["$", data.amount] })] }), _jsxs("div", { className: "flex gap-2 justify-between", children: [_jsx("p", { children: "Reason:" }), _jsx("span", { className: "font-medium", children: data.reason })] }), _jsxs("div", { className: "flex gap-2 justify-between", children: [_jsx("p", { children: "Status:" }), _jsx("span", { className: "font-medium", children: data.status === "REQUESTED" &&
                                                    !data.reviewed_by
                                                    ? "Pending for merchant supervisor review"
                                                    : data.status })] }), _jsx(CanAccess, { allowed_level: [
                                            Roles.Admin,
                                            Roles.Manager,
                                            Roles.Owner,
                                        ], children: data.status === "REQUESTED" &&
                                            !data.reviewed_by && (_jsx("button", { className: "bg-[#1BC5BD33] bg-opacity-20 text-[#1BC5BD] px-3 py-2 rounded-md", onClick: approveRefund, children: "Confirm refund" })) })] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsxs("div", { className: "flex gap-2 justify-between", children: [_jsx("p", { children: "Requested by:" }), _jsx("span", { className: "font-medium", children: data.initiated_by })] }), _jsxs("div", { className: "flex gap-2 justify-between", children: [_jsx("p", { children: "Reviewed by (merchant):" }), _jsx("span", { className: "font-medium", children: (_a = data.reviewed_by) !== null && _a !== void 0 ? _a : "-" })] }), _jsxs("div", { className: "flex gap-2 justify-between", children: [_jsx("p", { children: "Processed by:" }), _jsx("span", { className: "font-medium", children: (_b = data.processed_by) !== null && _b !== void 0 ? _b : "-" })] })] })] })] }) }) }));
}
