var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { haveCheckedAuthState, userState } from "../Auth/state";
import { useEffect, useState } from "react";
import { merchantState, merchantsState, rolesState } from "../Home/state";
import { useAPI } from "@/hooks/useAPI";
import Loader from "../Loader";
export var ProtectedRoute = function () {
    var navigate = useNavigate();
    var hitAPI = useAPI();
    var _a = useRecoilState(userState), user = _a[0], setUser = _a[1];
    var _b = useRecoilState(merchantState), merchant = _b[0], setMerchant = _b[1];
    var setMerchants = useSetRecoilState(merchantsState);
    var _c = useRecoilState(haveCheckedAuthState), checked = _c[0], setChecked = _c[1];
    var _d = useRecoilState(rolesState), roles = _d[0], setRoles = _d[1];
    var _e = useState(), currentRole = _e[0], setCurrentRole = _e[1];
    useEffect(function () {
        if (!merchant)
            return;
        setCurrentRole(roles.find(function (r) { return r.id == merchant.role_id; }));
    }, []);
    var checkLogin = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, found;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, hitAPI("/auth/profile")];
                case 1:
                    response = _a.sent();
                    if (!(response.status === 200)) return [3 /*break*/, 3];
                    // We've checked the login status
                    setChecked(true);
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    // Check length of data, if it's 0 then
                    // we set merchant as false
                    if (data.data.merchants.length === 0) {
                        setMerchant(false);
                        return [2 /*return*/, false];
                    }
                    // Otherwise, set the merchant
                    // 1. Check if current merchant is set
                    if (merchant) {
                        found = data.data.merchants.find(function (m) { return m.id === merchant.id; });
                        // 3. If not found, set the first merchant
                        // If found then re-set the merchant
                        setMerchant(found || data.data.merchants[0]);
                        // 4. Set the current role
                        setCurrentRole(roles.find(function (r) { return r.id == merchant.role_id; }));
                    }
                    else {
                        // 2. If not set then set the first merchant
                        setMerchant(data.data.merchants[0]);
                    }
                    // Save all merchants
                    setMerchants(data.data.merchants);
                    // If user is not set, set the user
                    if (!user)
                        setUser(data.data);
                    // Get available roles
                    hitAPI("/utils/roles")
                        .then(function (response) { return response.json(); })
                        .then(function (data) {
                        setRoles(data.data.data);
                    });
                    return [2 /*return*/, true];
                case 3:
                    setMerchant(null);
                    navigate("/auth/login");
                    return [2 /*return*/, false];
            }
        });
    }); };
    useEffect(function () {
        if (!checked)
            checkLogin();
    }, []);
    // If still checking for login status, show a loader
    if (!checked || merchant === null)
        return _jsx(Loader, {});
    // Check whether user is logged in or not
    // by checking whether we have the user object
    if (!user)
        return _jsx(Navigate, { to: "/auth/login" });
    // If user is logged in but doesn't have a merchant assigned to them
    // then we show an error message
    if (merchant === false)
        return (_jsx("div", { className: "flex items-center justify-center h-screen", children: _jsxs("div", { className: "text-center", children: [_jsx("h1", { className: "text-3xl font-bold", children: "No Merchant Found" }), _jsx("p", { className: "max-w-[50ch]", children: "You are logged in but you don't have a merchant assigned to you. Please contact the administrator." })] }) }));
    // All good
    return _jsx(Outlet, {});
};
