var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@/components/Button";
import Input from "@/components/Form/Input";
import PhoneInput from "react-phone-number-input/input";
import { useAPI } from "@/hooks/useAPI";
import toast from "react-hot-toast";
export default function Step1(_a) {
    var _this = this;
    var form = _a.form, setForm = _a.setForm, next = _a.next;
    var hitAPI = useAPI();
    var handleSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var response, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    return [4 /*yield*/, hitAPI("/auth/otp/request", "POST", {
                            phone: form.user.phone_number,
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _a.sent();
                    if (response.ok)
                        next();
                    else
                        toast.error(data.message);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: "flex flex-col gap-8 px-6 lg:px-14 mb-6", children: [_jsxs("div", { className: "flex items-center justify-center", children: [_jsx("span", { className: "w-6 h-6 flex items-center justify-center bg-[#1BC5BD] rounded-full font-medium text-xs p-4 text-white", children: "1" }), _jsx("span", { className: "h-[1px] w-[3rem] bg-[#1BC5BD]" }), _jsx("span", { className: "w-6 h-6 flex items-center justify-center border border-[#1BC5BD] rounded-full font-medium text-xs p-4 text-[#1BC5BD]", children: "2" }), _jsx("span", { className: "h-[1px] w-[3rem] bg-[#1BC5BD]" }), _jsx("span", { className: "w-6 h-6 flex items-center justify-center border border-[#1BC5BD] rounded-full font-medium text-xs p-4 text-[#1BC5BD]", children: "3" })] }), _jsxs("div", { className: "flex flex-col gap-2", children: [_jsx("h1", { className: "text-xl text-[#123750]", children: "Sign Up" }), _jsx("h3", { className: "text-[#123750]", children: "Your Details" }), _jsx("p", { className: "text-xs font-medium text-[#858585]", children: "Tell us a little bit about yourself" })] }), _jsxs("form", { onSubmit: handleSubmit, className: "flex flex-col gap-4", children: [_jsxs("div", { className: "flex flex-col gap-4 w-full", children: [_jsx(Input, { label: "Full Name", name: "name", required: true, value: form.user.name, onChange: function (e) {
                                    return setForm(function (form) {
                                        return __assign(__assign({}, form), { user: __assign(__assign({}, form.user), { name: e.target.value }) });
                                    });
                                } }), _jsx(Input, { label: "Email", name: "email", required: true, value: form.user.email, onChange: function (e) {
                                    return setForm(function (form) {
                                        return __assign(__assign({}, form), { user: __assign(__assign({}, form.user), { email: e.target.value }) });
                                    });
                                } }), _jsx(Input, { label: "Password", name: "password", type: "password", required: true, autoComplete: "new-password", value: form.user.password, onChange: function (e) {
                                    return setForm(function (form) {
                                        return __assign(__assign({}, form), { user: __assign(__assign({}, form.user), { password: e.target.value }) });
                                    });
                                } }), _jsx(Input, { label: "Re-enter Password", name: "password_confirmation", type: "password", required: true, autoComplete: "new-password", value: form.user.password_confirmation, onChange: function (e) {
                                    return setForm(function (form) {
                                        return __assign(__assign({}, form), { user: __assign(__assign({}, form.user), { password_confirmation: e.target.value }) });
                                    });
                                } }), _jsxs("div", { className: "flex flex-col gap-2 w-full", children: [_jsxs("label", { className: "text-xs text-[#858585] font-medium", children: ["Contact Number", _jsx("span", { className: "text-[#EB5757]", children: "*" })] }), _jsx(PhoneInput, { country: "BN", className: "bg-[#FAFAFA] border border-[#B5B5C3] py-3 px-4 rounded-[5rem] text-xs", placeholder: "Enter phone number", international: true, withCountryCallingCode: true, value: form.user.phone_number, onChange: function (e) {
                                            return e &&
                                                setForm(function (form) {
                                                    return __assign(__assign({}, form), { user: __assign(__assign({}, form.user), { phone_number: e }) });
                                                });
                                        } }), _jsx("span", { className: "text-[0.625rem] text-[#858585]", children: "An OTP (one time password) will be sent to your mobile number to verify your account" })] })] }), _jsx(Button, { type: "submit", children: "Next" })] })] }));
}
